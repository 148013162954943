import React, { memo } from "react"
import PropTypes from "prop-types"

const TableItemProgress = memo(({ value }) => {
  if (typeof value === "number") {
    const opacity = value * 0.01
    return (
      <div className="ori-flex-column ori-flex-jc ori-flex-ac">
        <p className="">{value}%</p>
        <div
          className="ori-t-pad-5 ori-border-light ori-border-radius-base ori-width-70 ori-bg-primary"
          style={{ opacity: opacity <= 0.15 ? 0.15 : opacity }}
        />
      </div>
    )
  } else return null
})

TableItemProgress.propTypes = {
  value: PropTypes.number,
}

export default TableItemProgress

import dayjs from "dayjs"

const states = {
  apilogs_details: {
    apilogs: [],
    has_more: false,
    selected_filters: {
      isSuccess: true,
      statusCode: 200,
      sessionId: "",
      startDate: dayjs().subtract(1, "day"),
      endDate: dayjs(),
    },
    selected_api: {
      id: "",
      response_data: null,
    },
  },
}

export default states

import { SIDER_MENU } from "../constants/layout"

export const getLang = () => {
  return navigator.languages && navigator.languages.length
    ? navigator.languages[0].split("-")[0]
    : navigator.language.split("-")[0]
}

export const checkDevice = {
  screen_orientation: function () {
    if (window.matchMedia("(orientation:landscape)").matches) {
      return "landscape"
    } else {
      return "portrait"
    }
  },
  screen_type: function () {
    if (window.innerWidth <= 480) {
      return "xs"
    } else if (window.innerWidth <= 768) {
      return "sm"
    } else if (window.innerWidth <= 992) {
      return "md"
    } else if (window.innerWidth <= 1200) {
      return "lg"
    } else if (window.innerWidth <= 1600) {
      return "hd"
    } else if (window.innerWidth <= 2560) {
      return "fhd"
    } else {
      return "uhd"
    }
  },
  deviceStatus: function () {
    return {
      screen_width: window.innerWidth,
      screen_height: window.innerHeight,
      screen_orientation: this.screen_orientation(),
      screen_type: this.screen_type(),
    }
  },
}

export const isModuleAccessible = (features, path, parent_path) => {
  let isAllowed = false
  if (path && features) {
    isAllowed = features[path] ? features[path].isAllowed : false
    if (
      parent_path &&
      features[parent_path] &&
      features[parent_path].children &&
      features[parent_path].children[path]
    )
      isAllowed =
        features[parent_path].isAllowed &&
        features[parent_path].children[path].isAllowed
  }
  return isAllowed
}

const bgClasses = [
  "ori-bg-warning",
  "ori-bg-info",
  "ori-bg-violet",
  "ori-bg-green",
  "ori-bg-danger",
  "ori-bg-yellow",
  "ori-bg-magenta",
  "ori-bg-primary",
  "ori-bg-red",
]

export const getBgClass = name => {
  if (name) {
    const index = name.length % bgClasses.length
    return bgClasses[index]
  }
  return "ori-bg-primary"
}

export const stringToColour = str => {
  var hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  var colour = "#"
  for (let i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff
    colour += ("00" + value.toString(16)).substr(-2)
  }
  return colour
}

export const getModulesByPermission = features => {
  const menu = []
  if (features) {
    SIDER_MENU.forEach(item => {
      const isAllowed = isModuleAccessible(features, item.routepath)
      if (isAllowed) {
        if (item.children && item.children.length > 0) {
          const itemChildren = []
          item.children.forEach(child => {
            const isChildAllowed = isModuleAccessible(
              features,
              child.routepath,
              item.routepath
            )
            if (isChildAllowed) itemChildren.push(child)
          })
          item.children = itemChildren
        }
        menu.push(item)
      }
    })
  }

  return menu
}

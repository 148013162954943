//-------------------------- IMAGE ---------------------------

export const IMG_LOGO = require("./img/logo.png")
export const IMG_LOGO_DARK = require("./img/logoDark.png")
export const IMG_LOGO_AVATAR = require("./img/logoAvatar.png")
export const IMG_LOGO_AVATAR_DARK = require("./img/logoAvatarDark.png")
export const IMG_LOGIN_BG = require("./img/loginBg.png")
export const IMG_BOT_AVATAR = require("./img/botAvatar.png")
export const IMG_ERROR = require("./img/error.png")
export const IMG_GRAPH = require("./img/graph.png")
export const IMG_EMPTY = require("./img/empty.png")
export const IMG_USER = require("./img/user.png")
export const IMG_PERSON = require("./img/person.png")
export const IMG_DODDLE = require("./img/doddle.png")
export const IMG_CHATTING = require("./img/chatting.png")
export const IMG_NEW_MESSAGE = require("./img/newMessage.png")
export const IMG_NEW_USER = require("./img/newUser.png")
export const IMG_AUTH_BG = require("./img/authBg.png")

//-------------------------- AUDIO ---------------------------

export const AUDIO_NOTIFICATION = require("./audio/notification.mp3")

import { MODULE_CONFIG } from "../../config/setup"
const states = {
  model_management_details: {
    //Model Table
    models: [],
    mt_current_page: 1, //  mt->model table
    mt_page_size: MODULE_CONFIG.MODEL_MANAGEMENT.MODELS_PER_PAGE_TABLE_DATA,
    mt_total: 0,
    mt_loading: false,
    mt_load_error: false,
    mt_inprogress_env:[],
    //Model Graph
    modelGraph: [],
    mg_loading: false, // mg-model graph
    mg_load_error: false,
    //upload
    accuracy: null,
    //deployedlogs
    deployment_log: [],
    dl_load_more: false,
  },
}
export default states
import { message } from "antd"

import {
  UPDATE_CHATS_STATE,
  MAKE_CHATS_SOCKET_CONNECTION,
  CALL_CHATS_SOCKET_METHOD,
  CHATS_SOCKET_DISCONNECT,
  EMIT_CHATS_CUSTOM_EVENT,
  JOIN_CHATS_PAGE,
  JOIN_CHAT_ROOM,
  CLOSE_CHAT_INTERFACE,
  CHATS_UPDATION,
  CHATS_DELETION,
  UPDATE_ACTIVE_CHATS_ITEM,
  EMIT_MESSAGE_SEEN,
  CHAT_ITEM_CLICKED,
  SWITCH_ALREADY_JOINED_CHAT,
  UNTAGGED_CHATLOG_RECEIVED,
} from "./actiontypes"
import { AM_UPDATE_AGENT_ACTIVE_CHATS_ITEM } from "../agent_monitoring_details/actiontypes"
import { HST_UPDATE_SELECTED_CHAT } from "../history_details/actiontypes"
import { CUST_UPDATE_SELECTED_CHATLOG } from "../customers_details/actiontypes"
import { TKT_CUSTOMER_PROFILE_RECEIVED } from "../tickets_details/actiontypes"
import * as API from "./api"
import { getSelectedChatlogDetails } from "../history_details/api"
import { fetchDataAndProceed } from "../../utils/dataUtility"
import { updateProfileSectionData } from "../../utils/common"
import { METHOD_TYPES } from "../../constants/common"
import { CHAT_LIST_TYPE } from "../../constants/chats"
import { APP_PAGES } from "../../constants/layout"

export const updateChatsState = payload => ({
  type: UPDATE_CHATS_STATE,
  payload,
})

export const updateActiveChatsItem = payload => ({
  type: UPDATE_ACTIVE_CHATS_ITEM,
  payload,
})

export const makeChatsSocketConnection = host => ({
  type: MAKE_CHATS_SOCKET_CONNECTION,
  host,
})

export const callChatsSocketMethod = payload => ({
  type: CALL_CHATS_SOCKET_METHOD,
  payload,
})

export const chatsSocketDisconnect = () => ({
  type: CHATS_SOCKET_DISCONNECT,
})

export const emitChatsCustomEvent = (event, payload, callback) => ({
  type: EMIT_CHATS_CUSTOM_EVENT,
  payload,
  event,
  callback,
})

export const emitMessageSeen = () => ({
  type: EMIT_MESSAGE_SEEN,
})

export const joinChatsPage = () => ({
  type: JOIN_CHATS_PAGE,
  payload: {},
})

export const closeChatInterface = () => ({
  type: CLOSE_CHAT_INTERFACE,
})

export const updateChatItem = payload => ({
  type: CHATS_UPDATION,
  payload,
})

/**
 *
 * @param {id , type : selected_active_chat_type} payload
 * @param {*} reason
 * @returns
 */

export const deleteChatItem = (payload, reason) => ({
  type: CHATS_DELETION,
  payload,
  reason,
})

export const joinChatRoom = payload => ({
  type: JOIN_CHAT_ROOM,
  payload,
})

export const chatItemClicked = payload => ({
  type: CHAT_ITEM_CLICKED,
  payload,
})

export const switchAlreadyJoinedChat = payload => ({
  type: SWITCH_ALREADY_JOINED_CHAT,
  payload,
})

export const getUntaggedChatlogs = data => dispatch => {
  fetchDataAndProceed(
    {
      url: getSelectedChatlogDetails,
      method: METHOD_TYPES.POST,
      data: { sessionId: data.sessionId },
      loader_text: "loading",
    },
    (error, response) => {
      if (
        !error &&
        response &&
        response.sessionId &&
        response.chatLogs &&
        response.userProfileDetails
      ) {
        dispatch({
          type: UNTAGGED_CHATLOG_RECEIVED,
          payload: response,
          id: data.id,
        })
      }
    }
  )
}

export const getJoinedRoomCustomerProfile = (data, type) => dispatch => {
  dispatch(updateChatsState({ customer_profile_loading: true }))
  fetchDataAndProceed(
    {
      url: API.getJoinedRoomCustomerProfile,
      method: METHOD_TYPES.POST,
      data,
      loader: false,
    },
    (error, response) => {
      dispatch(updateChatsState({ customer_profile_loading: false }))
      if (
        !error &&
        response &&
        response.data &&
        response.data.changedUserProfileDetails &&
        response.data.changedUserProfileDetails.psid &&
        response.data.changedUserProfileDetails.userProfileDetails
      ) {
        const profile_data = {
          ...response.data.changedUserProfileDetails.userProfileDetails,
          eventListenTimeStamp: new Date().getTime(),
        }
        if (profile_data.sections && profile_data.sections.length > 0)
          profile_data.sections = updateProfileSectionData(
            profile_data.sections
          )
        dispatch({
          type:
            type === CHAT_LIST_TYPE.AGENT_CHATS
              ? AM_UPDATE_AGENT_ACTIVE_CHATS_ITEM
              : UPDATE_ACTIVE_CHATS_ITEM,
          payload: {
            id: response.data.changedUserProfileDetails.psid,
            key: "profile",
            value: profile_data,
            is_array: false,
          },
        })
      }
    }
  )
}

export const updateCustomAgentNoteForm =
  (data, payload, callback) => dispatch => {
    fetchDataAndProceed(
      {
        url: API.customAgentNoteForm,
        method: METHOD_TYPES.PUT,
        data,
        loader_text: "updating",
      },
      (error, response) => {
        if (!error && response) {
          if (callback) callback()
          if (
            payload.type === APP_PAGES.HISTORY ||
            payload.type === APP_PAGES.CUSTOMER_PROFILE
          ) {
            dispatch({
              type:
                payload.type === APP_PAGES.HISTORY
                  ? HST_UPDATE_SELECTED_CHAT
                  : CUST_UPDATE_SELECTED_CHATLOG,
              payload: {
                key: "profile",
                sessionId: data.sessionId,
                value: payload.profile_data,
              },
            })
          } else if (payload.type === APP_PAGES.TICKETS) {
            dispatch({
              type: TKT_CUSTOMER_PROFILE_RECEIVED,
              payload: {
                profile: payload.profile_data,
                ticketId: payload.ticketId,
              },
            })
          } else {
            dispatch({
              type:
                payload.type === CHAT_LIST_TYPE.AGENT_CHATS
                  ? AM_UPDATE_AGENT_ACTIVE_CHATS_ITEM
                  : UPDATE_ACTIVE_CHATS_ITEM,
              payload: {
                id: payload.id,
                key: "profile",
                value: payload.profile_data,
                is_array: false,
              },
            })
          }
          message.success(
            response.message ? response.message : "Agent note updated"
          )
        }
      }
    )
  }

// ----------------------------- OTHER API CALLS --------------------------------

export const getAgentLiveKpis = data => dispatch => {
  fetchDataAndProceed(
    {
      url: API.getAgentLiveKpis,
      method: METHOD_TYPES.POST,
      data,
      loader: false,
    },
    (error, response) => {
      if (!error && response && response.data && response.data.agentKpis)
        dispatch(updateChatsState({ agent_live_kpis: response.data.agentKpis }))
      else dispatch(updateChatsState({ agent_live_kpis: [] }))
    }
  )
}

export const updateCustomerLanguage = (data ,callback) => dispatch => {
  fetchDataAndProceed(
    {
      url: API.updateAgentSelectedCustomerLang,
      method: METHOD_TYPES.PUT,
      data,
    },
    (error, response) => {
      if(!error && response){
        if(callback) callback()
        message.success(response.message ? response.message : "Customer Language Updated")
      } 
    }
  )
}